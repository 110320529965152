<template>
  <div class="work-record__page">
    <div class="tree-sub" ref="tree-sub">
      <div class="tree-menu first-menu" :style="treeHeight">
        <TreeMenu
          ref="treeMenu"
          :treeMenuName="$t('workRecord.project')"
          :searchName="$t('workRecord.findProject')"
          :projectArr="projectArr"
          :mutipleCheck="chooseTree === 'project'"
          :mutipleActive="true"
          @getChoosedContractItem="getChoosedContractItem"
        />
      </div>
      <div class="tree-menu" :style="treeHeight">
        <TreeMenu
          ref="treeMenu"
          :treeMenuName="$t('workRecord.publicAffairs')"
          :searchName="$t('workRecord.findPublicAffairs')"
          :projectArr="affairArr"
          :mutipleCheck="chooseTree === 'affairs'"
          :mutipleActive="true"
          @getChoosedContractItem="getChoosedContractItem"
        />
      </div>
    </div>
    <div id="tableRange" :style="{ width: tableWidth + 'px !important' }">
      <div class="record-box">
        <div class="header" ref="header">
          <p class="headerTitle">{{ $t('workRecord.projectHoursTable') }}</p>

          <div class="box">
            <div class="circle"></div>
            <p class="currentRord">{{ choosedContractItem.name }}</p>
          </div>
          <div class="sort-day">
            <div :class="['today', { today_en: $i18n.locale == 'en' }]">
              <span :class="focusDayCheck ? 'currentDateClass' : null">
                {{ focusDayCheck ? ' ' + currentDate + ' ' + $t('workRecord.weeklyCurrentDayHours') : $t('workRecord.todayWorkingHours') }}
              </span>
              ：<span class="num">{{ todayHours }} </span>
            </div>
            <div class="tweek">
              {{ $t('workRecord.weeklyWorkingHours') }}：<span class="num">{{ weekHours }} </span>
            </div>
          </div>
        </div>
        <div class="header-btns" ref="headerBtns">
          <ChooseWeek ref="chooseWeek" @getWeekRange="getWeekRange" />
          <div class="btns-group">
            <el-button
              size="mini"
              class="savebtn"
              :type="revocationDisabled ? 'info' : 'primary'"
              :plain="revocationDisabled"
              :disabled="revocationDisabled"
              @click="withDrawWorkHour"
              >{{ $t('workRecord.withdrawal') }}</el-button
            >
            <el-button
              size="mini"
              class="savebtn addSupplementBtn"
              :type="addSupplementDisabled ? 'info' : 'primary'"
              :plain="addSupplementDisabled"
              :disabled="addSupplementDisabled"
              @click="addSupplementWorkHour"
              >{{ $t('workRecord.addSupplement') }}</el-button
            >
            <el-button @click="saveWorkLog" class="savebtn create" size="medium" type="primary">{{ $t('workRecord.save') }}</el-button>
          </div>
        </div>
      </div>
      <RecordTaskDrawer
        :beginDate="beginDate"
        :endDate="endDate"
        :drawerVisible.sync="drawerVisible"
        :taskInfo="taskInfo"
        :projectName="choosedContractItem.name"
        :weeklyDay="weeklyDay"
        :yearlyDay="yearlyDay"
        :projectDisabled="projectDisabled"
        :changeItems="changeItems"
      ></RecordTaskDrawer>
      <WorkRecordTable
        :tableHeight="tableHeight"
        ref="workRecordTable"
        :tableData="tableData"
        :weeklyDay="weeklyDay"
        :yearlyDay="yearlyDay"
        :holidayWeek="holidayWeek"
        @switchTabHours="switchTabHours"
        :weeklyDuration="weeklyDuration"
        :tableLoading="tableLoading"
        :projectDisabled="projectDisabled"
        :changeItems="changeItems"
        @changeObjsData="changeObjsData"
        @taskDrawerExpand="taskDrawerExpand"
        :chooseTree="chooseTree"
      />
    </div>
  </div>
</template>

<script>
import TreeMenu from '@/components/TreeMenu/TreeMenu.vue';
import WorkRecordTable from './components/WorkRecordTable.vue';
import RecordTaskDrawer from './components/RecordTaskDrawer.vue';

import personalWorkingApi from '@/api/modules/personalWorking.api.js';
import workRecordApi from '@/api/modules/workRecord.api.js';

import { debounce } from '@/utils/debounce';
import { getDayAll, dayFormat } from '@/utils/time-tools';

import { mixinsWorkRecord } from '@/mixins/treeMenu';
export default {
  name: 'WorkRecord',
  components: { TreeMenu, WorkRecordTable, RecordTaskDrawer },
  mixins: [mixinsWorkRecord],
  data() {
    return {
      tableWidth: 0,
      tableHeight: 0,
      projectArr: [], //项目列表
      affairArr: [],
      tableData: [], //准备渲染表格的数据
      weeklyDay: [], //表格数据的日期
      yearlyDay: [], //带年份的表格数据的日期
      holidayWeek: [],
      weeklyDuration: [],
      weekRange: {}, //选择周的范围
      choosedContractItem: {}, //当前选择的项目
      tableLoading: false,
      changeItems: [], //被改变的单元格数据
      currentDate: '',
      projectDisabled: false,
      revocationDisabled: false,
      addSupplementDisabled: false,
      focusDayCheck: false,
      dayDuration: 0,
      todayHours: 0,
      weekHours: 0,
      chooseTree: 'project',
      firstLoadName: undefined,
      // 任务抽屉
      drawerVisible: false,
      taskInfo: {},
      beginDate: '',
      endDate: '',
    };
  },
  created() {
    this.createLoad();
  },
  methods: {
    taskDrawerExpand(row) {
      this.taskInfo = Object.assign({}, row);
      this.drawerVisible = true;
    },
    calTableDom() {
      const resizeDom = debounce(() => {
        // 上导航(包含内外边距) 路由菜单滑轨(包含内外边距) ref->top-box,header bottom-box的内边距和外边距
        this.otherDomHeight = (this.$refs.headerBtns?.offsetHeight ?? 0) + (this.$refs.header?.offsetHeight ?? 0) + 32 + 50 + 28 + 24 + 5 + 16;
        this.tableHeight = window.innerHeight - this.otherDomHeight;
      }, 0);
      resizeDom();
    },
    calTableRangeWidth() {
      this.tableWidth = window.innerWidth - this.$refs['tree-sub'].offsetWidth - 16 - 28 - (this.$store.getters.sidebar.opened ? 208 : 55);
    },
    withDrawWorkHour() {
      return this.$confirm(
        '<p>' + this.$t('workRecord.withdrawalDes') + '</p>' + this.$t('workRecord.withdrawalTips'),
        this.$t('commonVariables.notice'),
        {
          confirmButtonText: this.$t('commonVariables.complete'),
          cancelButtonText: this.$t('commonVariables.cancel'),
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          let beginDate = this.weekRange.startTime.split(' ')[0];
          let endDate = this.weekRange.endTime.split(' ')[0];
          workRecordApi
            .withDrawalAudit({
              projectId: this.choosedContractItem.id,
              projectType: this.chooseTree == 'project' ? 'project' : 'affair',
              beginDate,
              endDate,
            })
            .then(res => {
              if (!Object.keys(res[1]).length) {
                this.$message.success(this.$t('workRecord.withdrawalSucceeded'));
                this.chooseTree == 'project'
                  ? this.getWorkLog(this.choosedContractItem.id, this.weekRange.startTime.split(' ')[0])
                  : this.getUserAffairLog(this.choosedContractItem.id, this.weekRange.startTime.split(' ')[0]);
                this.getUserWorkTime();
              }
            })
            .catch(err => {
              this.$message.error('请求失败原因：' + err);
            });
        })
        .catch(() => {
          '已取消';
        });
    },
    addSupplementWorkHour() {
      return this.$confirm(
        '<p>' + this.$t('workRecord.addSupplementDes') + '</p>' + this.$t('workRecord.addSupplementTips'),
        this.$t('commonVariables.notice'),
        {
          confirmButtonText: this.$t('commonVariables.complete'),
          cancelButtonText: this.$t('commonVariables.cancel'),
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          let beginDate = this.weekRange.startTime.split(' ')[0];
          let endDate = this.weekRange.endTime.split(' ')[0];
          workRecordApi
            .addSupplementAudit({
              projectId: this.choosedContractItem.id,
              projectType: this.chooseTree == 'project' ? 'project' : 'affair',
              beginDate,
              endDate,
            })
            .then(res => {
              if (!Object.keys(res[1]).length) {
                this.$message.success(this.$t('commonVariables.submittedSuccessfully'));
                this.chooseTree == 'project'
                  ? this.getWorkLog(this.choosedContractItem.id, this.weekRange.startTime.split(' ')[0])
                  : this.getUserAffairLog(this.choosedContractItem.id, this.weekRange.startTime.split(' ')[0]);
                this.getUserWorkTime();
              }
            })
            .catch(err => {
              this.$message.error('请求失败原因：' + err);
            });
        })
        .catch(() => {
          '已取消';
        });
    },
    async createLoad() {
      await this.getProject();
      this.getAllAffairs();
    },
    //获取所有项目列表
    async getProject() {
      let [data] = await workRecordApi.getProject({ page: 1, pagesize: 99999 });
      if (!data || !data.project.length) return (this.tableData = []);
      this.projectArr = data.project;
      //默认获取第一个合同当前周的数据
      this.choosedContractItem = this.projectArr[0];

      this.getWorkLog(this.choosedContractItem.id, this.weekRange.startTime.split(' ')[0]);
    },
    //获取所有事项列表
    async getAllAffairs() {
      let [Affairsdata] = await workRecordApi.getPublicAffairs({ page: 1, pagesize: 99999 });
      if (!Affairsdata || !Affairsdata.affair.length) return (this.tableData = []);

      this.affairArr = Affairsdata.affair;
      /*       this.choosedContractItem = this.affairArr[0]; */
    },
    // 变更当周个人当日工时
    switchTabHours(vals, dateVal) {
      if (!vals.length) {
        this.todayHours = this.dayDuration;
        this.focusDayCheck = false;
        this.currentDate = '';
        return;
      }
      this.currentDate = dateVal;
      this.focusDayCheck = true;
      let [hours] = vals;
      this.todayHours = hours;
    },
    //获取周的时间
    getWeekRange(weekRange) {
      const { weekRangeData } = weekRange;
      this.beginDate = weekRangeData.startTime.split(' ')[0];
      this.endDate = weekRangeData.endTime.split(' ')[0];
      const fn = async () => {
        this.weekRange = weekRangeData;

        if (this.chooseTree === 'project') {
          this.choosedContractItem?.id && (await this.getWorkLog(this.choosedContractItem.id, this.weekRange.startTime.split(' ')[0]));
        } else {
          this.choosedContractItem?.id && (await this.getUserAffairLog(this.choosedContractItem.id, this.weekRange.startTime.split(' ')[0]));
        }
        const startTime = this.weekRange.startTime.split(' ')[0].replace(/-/g, '/');
        const endTime = this.weekRange.endTime.split(' ')[0].replace(/-/g, '/');

        this.weeklyDay = getDayAll(startTime, endTime).map(i => i.split('-').splice(1).join('-'));
        this.yearlyDay = getDayAll(startTime, endTime).map(i => i.split('-').splice(0).join('-'));

        this.getUserWorkTime();
      };
      if (this.changeItems.length) {
        // const catchfn = () => this.$refs['chooseWeek'].setLastChoose();
        this.noSave(fn);
      } else {
        fn();
      }
    },

    //获取选择的那个项目
    getChoosedContractItem(i, listType = false) {
      const fn = () => {
        if (listType) {
          this.choosedContractItem = i;
          this.choosedContractItem?.id && this.getUserAffairLog(this.choosedContractItem.id, this.weekRange.startTime.split(' ')[0]);
          this.chooseTree = 'affairs';
          return;
        }
        this.choosedContractItem = i;
        this.choosedContractItem?.id && this.getWorkLog(this.choosedContractItem.id, this.weekRange.startTime.split(' ')[0]);
        this.chooseTree = 'project';
      };
      if (this.changeItems.length) {
        const catchfn = () => (this.$refs['treeMenu'].chooseIdx = this.$refs['treeMenu'].lastChooseIdx);
        this.noSave(fn, catchfn);
      } else {
        fn();
      }
    },

    //获取用户工时
    async getUserWorkTime() {
      let [data] = await workRecordApi.getWorkTime({
        weekStart: this.weekRange.startTime.split(' ')[0],
        today: dayFormat(+new Date(), 'YYYY-MM-DD'),
      });
      if (!data) return;
      this.todayHours = data.dayDuration ? data.dayDuration : 0;
      this.dayDuration = data.dayDuration ? data.dayDuration : 0;
      this.weekHours = data.weekDuration ? data.weekDuration : 0;
    },

    async getWorkLog(projectId, startDate) {
      this.tableLoading = true;
      let startDates = this.weekRange.startTime.split(' ')[0];
      let endDate = this.weekRange.endTime.split(' ')[0];

      let [dateData] = await workRecordApi.getholidayLog({
        beginDate: startDates,
        endDate,
      });
      this.holidayWeek = [...dateData.dates];
      let [summaryCurrentDay] = await personalWorkingApi.getPersonWeekSummary({ weekStart: startDates });
      this.weeklyDuration = [...summaryCurrentDay.weekSummary.duration];
      let [data] = await workRecordApi.getWorkLog({ project: projectId, startDate });
      data.detail = data.detail || [];
      this.projectDisabled = data.disabled;
      this.addSupplementDisabled = !data.canCommit;
      this.revocationDisabled = !data.canRejected;
      //映射为table需要的数据格式
      this.tableData = data.detail.map(i => {
        return {
          contractNo: i.service.projecetSeq, //合同序号
          // serviceParentItem: i.service.servingParentName ? i.service.servingParentName : i.service.servingName, //服务父项名称
          servingName: i.service.servingParentName ? i.service.servingParentName : i.service.servingName, //服务项名称
          projectWorkItem: i.service.name ? i.service.name : null, //项目工作项名称
          canShowJob: i.service.canShowJob,
          mon: this.filterWeekDaysDuration(i, 1),
          tue: this.filterWeekDaysDuration(i, 2),
          wed: this.filterWeekDaysDuration(i, 3),
          thu: this.filterWeekDaysDuration(i, 4),
          fri: this.filterWeekDaysDuration(i, 5),
          sat: this.filterWeekDaysDuration(i, 6),
          sun: this.filterWeekDaysDuration(i, 7),
          weekSum: i.summary?.personal ?? 0,
          contractSum: i.summary?.total ?? 0,
          weekId: data.weekId, //周id
          serviceId: i.service.id, //服务id
          taskId: i.service.task, //任务id
        };
      });
      this.tableLoading = false;
    },

    async getUserAffairLog(affairId, startDate) {
      this.tableLoading = true;
      let [data] = await workRecordApi.getUserAffairLog({ startDate, affair: affairId });

      data.task = data.task || [];
      this.projectDisabled = data.disabled;
      this.addSupplementDisabled = !data.canCommit;
      this.revocationDisabled = !data.canRejected;
      //映射为table需要的数据格式
      this.tableData = data.task.map(i => {
        return {
          task: i.name, //任务名字
          mon: this.filterWeekDaysDuration(i, 1),
          tue: this.filterWeekDaysDuration(i, 2),
          wed: this.filterWeekDaysDuration(i, 3),
          thu: this.filterWeekDaysDuration(i, 4),
          fri: this.filterWeekDaysDuration(i, 5),
          sat: this.filterWeekDaysDuration(i, 6),
          sun: this.filterWeekDaysDuration(i, 7),
          weekSum: i.personal,
          contractSum: i.total,
          weekId: data.weekid, //周id
          arrairTaskId: i.id, //任务id
        };
      });
      this.tableLoading = false;
    },
    // 在这里为每一行的列内子数据进行数据匹配和数据填充
    filterWeekDaysDuration(i, weekNumber) {
      let hasData = undefined;
      // 区分接口字段的大小写
      if (this.chooseTree === 'affairs') {
        hasData = i.worklog.find(w => w.weekNumber === weekNumber);
      } else {
        hasData = i.workLog.find(w => w.weekNumber === weekNumber);
      }
      if (hasData) {
        hasData.remark = hasData.remark ?? '';
        hasData.duration = hasData.duration ?? '';
        return hasData;
      } else {
        return { duration: '', id: null, weekNumber, remark: '' };
      }
    },

    changeObjsData(changeItems) {
      this.changeItems = changeItems;
    },
    //保存改动的项目工作记录
    async saveWorkLog() {
      if (this.chooseTree === 'affairs') {
        this.saveAffairLog();
        return;
      }
      if (!this.changeItems.length) {
        this.$message.warning('没有改动项');
        return;
      }
      const overWorkDay = this.changeItems.find(val => val.duration > 24);
      if (overWorkDay) {
        this.$message.error('存在不符合填写条件的工时，请修改后再次提交');
        return;
      }
      let p = {
        weekId: this.changeItems[0].weekId,
        detail: [],
      };
      // 8/15变更，参照ID从service改成taskId
      // const differentServiceId = [...new Set(this.changeItems.map(i => i.serviceId))]; //更改了哪几行任务项
      const differentTaskId = [...new Set(this.changeItems.map(i => i.taskId))]; //更改了哪几行任务项
      for (let idx in differentTaskId) {
        const sameTaskIdItems = this.changeItems.filter(a => a.taskId === differentTaskId[idx]);
        p.detail[idx] = {
          task: differentTaskId[idx],
          workLog: [],
        };
        for (let sameI of sameTaskIdItems) {
          p.detail[idx].service = sameI.serviceId;
          p.detail[idx].workLog.push({
            id: sameI.workLogId,
            weekNumber: sameI.weekNumber,
            duration: sameI.duration || 0,
            remark: sameI.remark,
          });
        }
      }

      let [data] = await workRecordApi.submitWorkLog(p);
      if (!data) return;
      this.$message.success(this.$t('commonVariables.savedSuccessfully'));
      this.changeItems = [];
      this.getWorkLog(this.choosedContractItem.id, this.weekRange.startTime.split(' ')[0]);
      this.getUserWorkTime();
    },
    //保存改动的公共事项工作记录
    async saveAffairLog() {
      if (!this.changeItems.length) {
        this.$message.warning('没有改动项');
        return;
      }
      const overWorkDay = this.changeItems.find(val => val.duration > 24);
      if (overWorkDay) {
        this.$message.error('存在不符合填写条件的工时，请修改后再次提交');
        return;
      }
      let p = {
        weekid: this.changeItems[0].weekId,
        task: [],
      };

      const differentTaskId = [...new Set(this.changeItems.map(i => i.taskId))]; //更改了哪几行任务项
      for (let idx in differentTaskId) {
        const sameTaskIdItems = this.changeItems.filter(a => a.taskId === differentTaskId[idx]);
        p.task[idx] = {
          id: differentTaskId[idx],
          worklog: [],
        };
        for (let sameI of sameTaskIdItems) {
          p.task[idx].worklog.push({
            id: sameI.workLogId,
            weekNumber: sameI.weekNumber,
            duration: sameI.duration || 0,
            remark: sameI.remark,
          });
        }
      }

      let [data] = await workRecordApi.sumbitAffairLog(p);
      if (!data) return;
      this.$message.success(this.$t('commonVariables.savedSuccessfully'));
      this.changeItems = [];
      this.getUserAffairLog(this.choosedContractItem.id, this.weekRange.startTime.split(' ')[0]);
      this.getUserWorkTime();
    },
    noSave(dofn, catchfn) {
      this.$confirm(this.$t('commonVariables.saveWarnTips'), this.$t('commonVariables.notice'), {
        confirmButtonText: this.$t('commonVariables.confirmDelete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
        type: 'warning',
      })
        .then(() => {
          dofn();
          this.changeItems = [];
        })
        .catch(() => {
          catchfn && catchfn();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
@import '@/styles/compsStyle/resource.scss';
@import '@/styles/element-ui.scss';
.work-record__page {
  display: flex;
  .tree-sub {
    margin-left: 24px;
    .tree-menu {
      ::v-deep .tree-menu__box {
        .tit {
          // height: 68px;
          // line-height: 80px;
          padding-top: 22px;
          margin-bottom: 6px;
        }
      }
    }
    .first-menu {
      margin-bottom: 16px;
    }
  }
  #tableRange {
    background-color: #fff;
    padding: 12px 24px;
    border-radius: 2px;
    margin: 0 16px;
    // margin-right: 24px;
    margin-top: 0;
    overflow: hidden;
    flex: 1;

    .record-box {
      .header {
        display: flex;
        align-items: center;
        padding-bottom: 12px;
        border-bottom: 1px solid #e8eaec;
        height: 56px;
        .headerTitle {
          margin: 0;
          display: inline-block;
          font-size: 15px;
          font-weight: 600;
          color: #17233d;
        }
        .sort-day {
          display: flex;
          justify-content: flex-start;
          margin-left: auto;
          font-size: 14px;
          .today,
          .tweek {
            // min-width: 180px;
            margin-left: 44px;
            line-height: 22px;
            .currentDateClass {
              font-weight: bold;
            }
            .num {
              color: #2d8cf0;
            }
          }
        }
        .box {
          display: inline-block;
          position: relative;
          // min-width: 92px;
          min-height: 24px;
          margin-left: 8px;

          background: #f5f7fa;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2d8cf0;
          .circle {
            position: absolute;
            top: calc(50% - 3px);
            left: 12px;
            border-radius: 50%;
            width: 6px;
            height: 6px;
            background: #2d8cf0;
          }
          .currentRord {
            color: #515a6e;
            display: inline-block;
            margin: 0;
            padding-left: 24px;
            padding-right: 12px;
            line-height: 24px;
            font-size: 13px;
          }
        }
      }

      .header-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        flex: auto;
        margin: 14px 0px 14px 0;
        height: 32px;
        font-size: 14px;

        // .header-filter {
        //   display: flex;
        //   align-items: center;
        //   height: 32px;
        //   padding-right: 16px;
        //   .today,
        //   .tweek {
        //     min-width: 170px;
        //     // margin-left: 20px;
        //     .currentDateClass {
        //       font-weight: bold;
        //     }
        //     .num {
        //       color: #2d8cf0;
        //     }
        //   }
        //   .today {
        //     margin-left: 16px;
        //   }
        //   .today_en {
        //     margin-right: 6px;
        //   }
        // }
        .savebtn {
          font-size: 14px;
          margin-left: 0;
          height: 32px;
          padding-top: 0;
          padding-bottom: 0;
          // line-height: 32px;
        }
        .addSupplementBtn {
          margin-left: 10px;
        }
        .create {
          margin-left: 10px;
        }
      }
    }
  }
}
@media screen and (min-width: 350px) and (max-width: 900px) {
  .tree-sub {
    margin-left: 8px !important;
  }
  ::v-deep .tree-menu__box {
    max-width: 188px !important;
  }
  #tableRange {
    flex: auto !important;
    background-color: #fff;
    padding: 12px 12px !important;
    border-radius: 2px;
    margin: 0 16px !important;
    margin-right: 12px !important;
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 900px) and (max-width: 1550px) {
  .tree-sub {
    margin-left: 16px !important;
  }
  ::v-deep .tree-menu__box {
    max-width: 208px !important;
  }
  #tableRange {
    flex: auto !important;
    background-color: #fff;
    padding: 12px 12px !important;
    border-radius: 2px;
    margin: 0 16px !important;
    margin-right: 12px !important;
    margin-top: 0 !important;
  }
}
</style>
