<template>
  <div>
    <el-drawer append-to-body id="taskFormDrawer" :with-header="false" :visible="drawerVisible" :size="drawerSize">
      <div class="drawerHeader" ref="drawerHeader">
        <p class="title">{{ $t('workRecord.fillProgress') }}</p>
        <div class="header-btn-group">
          <el-button class="btn" size="mini" type="default" @click="afterCloseClear">{{ $t('commonVariables.cancel') }}</el-button>
          <el-button class="btn" size="mini" type="primary" @click="recordSave">{{ $t('commonVariables.save') }}</el-button>
        </div>
      </div>
      <div class="app-box">
        <div ref="formContainer" class="formContainer">
          <el-form ref="drawerForm" class="form-box" :model="drawerForm" label-position="top">
            <div class="des-oneRow">
              <el-form-item :label="$t('workRecord.projectName')" label-position="top">
                <el-input v-model="projectName" :disabled="true" class="projInput"></el-input>
              </el-form-item>

              <el-form-item :label="$t('workRecord.serviceItem')" label-position="top">
                <el-input v-model="taskInfo.servingName" :disabled="true" class="projInput"></el-input>
              </el-form-item>

              <el-form-item :label="$t('workRecord.projectWorkItem')" label-position="top">
                <el-input v-model="taskInfo.projectWorkItem" :disabled="true" class="projInput"></el-input>
              </el-form-item>

              <el-form-item :label="$t('workRecord.currentWeek')" label-position="top">
                <el-input v-model="date" :disabled="true" class="projInput"></el-input>
              </el-form-item>
            </div>

            <div class="nameCont">
              <el-form-item prop="nameCont" :label="$t('workRecord.nameCont')">
                <el-input v-model="drawerForm.nameCont" :placeholder="$t('workRecord.nameContTips')" style="width: 280px"></el-input>
                <el-button class="btn btnForm" size="mini" type="primary" @click="searchData">{{ $t('commonVariables.filtrate') }}</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>

        <div class="tableContainer">
          <el-table
            class="taskTable"
            v-loading="tableLoading"
            :data="taskTableData"
            border
            :height="tableHeight"
            highlight-current-row
            empty-text=" "
            ref="table"
          >
            <el-table-column show-overflow-tooltip :align="'left'" :label="$t('workRecord.taskItem')" min-width="180px">
              <template slot-scope="scope">
                <div style="width: 100%">
                  <span>{{ scope.row.name }}</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              :align="'center'"
              v-for="(item, idx) in weeklySums"
              :key="item.simpDes"
              :min-width="$i18n.locale == 'en' ? '86px' : '76px'"
            >
              <template #header>
                <div :tabindex="idx" class="header-text">
                  <div :class="{ currentDColor: yearlyDay[idx] === currentDayDate }">
                    {{ yearlyDay[idx] === currentDayDate ? $t('workRecord.today') : $t('workRecord.' + item.fullDes) }}
                    <div class="dayStyle">{{ weeklyDay[idx] }}</div>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <div class="inputItem">
                  <template v-if="!inputTip(idx)">
                    <div class="inputShadow" @click="clickThisRow(scope.row, idx)">
                      {{ scope.row.logs[idx].size }}
                    </div>
                  </template>
                </div>
                <div v-if="inputShow && inputTip(idx)" class="scope-input">
                  <el-input
                    :disabled="scope.row.logs[idx].disabled"
                    @change="collectionChange(scope.$index, scope.row.logs[idx], scope.row)"
                    v-model="scope.row.logs[idx].size"
                  >
                  </el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column :align="'center'" :label="$t('workRecord.finishNum')" min-width="90px">
              <template slot-scope="scope">
                <div style="display: flex; align-content: center; justify-content: center">
                  <span>{{ scope.row.amount }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :align="'center'" :label="$t('workRecord.size')" min-width="60px">
              <template slot-scope="scope">
                <div style="display: flex; align-content: center; justify-content: center">
                  <span>{{ scope.row.size }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="drawerForm.page"
            :page-sizes="[10, 20, 30, 50, 100]"
            :page-size="drawerForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count"
          >
          </el-pagination>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { dayFormat } from '@/utils/time-tools';
import workRecordApi from '@/api/modules/workRecord.api.js';

export default {
  props: {
    drawerVisible: {
      type: Boolean,
      default: false,
    },
    projectName: {
      type: String,
      default: '',
    },
    taskInfo: {
      type: Object,
      default: () => {},
    },

    beginDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
    holidayWeek: {
      type: Array,
      default: () => {
        return [];
      },
    },
    yearlyDay: {
      type: Array,
      default: () => {
        return [];
      },
    },
    weeklyDay: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  computed: {
    date() {
      return this.beginDate + '  至  ' + this.endDate;
    },
    elDescriptionLabelStyle() {
      return {
        'text-align': 'center',
        width: '96px',
        'white-space': 'nowrap',
      };
    },

    elDescriptionContentStyle() {
      return {
        'text-align': 'left',
        width: '520px',
        'white-space': 'nowrap',
        'text-overflow': 'ellipsis',
        overflow: 'hidden',
      };
    },
  },

  watch: {
    drawerVisible: {
      deep: true,
      immediate: false,
      handler(newVal) {
        if (newVal) {
          this.tableLoading = true;
          this.drawerForm.dateGte = this.beginDate;
          this.drawerForm.dateLte = this.endDate;
          const q = {
            taskIdEq: this.taskInfo.taskId,
            ...this.drawerForm,
          };
          workRecordApi.getTaskList(q).then(res => {
            if (res[2].status !== 200) {
              this.$message.error('请求出错');
              this.tableLoading = false;

              return;
            }

            this.taskTableData = [...res[0].jobs];
            this.count = res[0].count;
            this.tableLoading = false;
          });
          if (this.firstCreate) {
            this.resizeObserverSize();
            window.removeEventListener('resize', this.resizeObserverSize);
          }
          this.firstCreate = false;
        }
      },
    },
    weeklyDay: {
      deep: true,
      immediate: false,
      handler() {
        this.currentDayDate = dayFormat(+new Date(), 'YYYY-MM-DD');
      },
    },
  },
  activated() {
    this.$nextTick(() => {
      !this.firstCreate ? this.resizeObserverSize() : null;
    });
    window.addEventListener('resize', this.resizeObserverSize);
  },
  deactivated() {
    window.removeEventListener('resize', this.resizeObserverSize);
  },
  data() {
    return {
      logMixins: [],
      firstCreate: true,
      currentDayDate: '',
      showEmpty: false,
      count: 0,
      inputShow: false,
      drawerForm: {
        page: 1,
        pageSize: 20,
        nameCont: '',
        dateGte: '',
        dateLte: '',
      },
      indexId: null,
      currentRow: {},
      taskTableData: [],
      drawerSize: '80%',
      tableLoading: false,
      tableHeight: 0,
      weeklySums: [
        { fullDes: 'monday', simpDes: 'mon', weekIndex: 0 },
        { fullDes: 'tuesday', simpDes: 'tue', weekIndex: 1 },
        { fullDes: 'wednesday', simpDes: 'wed', weekIndex: 2 },
        { fullDes: 'thursday', simpDes: 'thu', weekIndex: 3 },
        { fullDes: 'friday', simpDes: 'fri', weekIndex: 4 },
        { fullDes: 'saturday', simpDes: 'sat', weekIndex: 5 },
        { fullDes: 'sunday', simpDes: 'sun', weekIndex: 6 },
      ],
    };
  },
  methods: {
    handleSizeChange(val) {
      this.drawerForm.pageSize = val;
      this.searchData();
    },
    handleCurrentChange(val) {
      this.drawerForm.page = val;
      this.searchData();
    },
    collectionChange(idx, currentLog, row) {
      const isCollect = this.logMixins.findIndex(item => {
        return currentLog.jobId === item.jobId && currentLog.date === item.date;
      });
      let sum = 0;
      row.logs.forEach(item => {
        const subSize = Number(item.size);
        item.size = subSize ? Number(subSize.toFixed(2)) : null;
        sum = subSize ? sum + Number(subSize.toFixed(2)) : sum;
      });
      if (!~isCollect) {
        this.logMixins.push(currentLog);
      } else {
        this.logMixins[isCollect] = JSON.parse(JSON.stringify(currentLog));
      }
      this.taskTableData[idx].amount = Number(sum.toFixed(2));
    },
    searchData() {
      this.tableLoading = true;

      this.drawerForm.dateGte = this.beginDate;
      this.drawerForm.dateLte = this.endDate;
      const q = {
        taskIdEq: this.taskInfo.taskId,
        ...this.drawerForm,
      };
      workRecordApi.getTaskList(q).then(res => {
        if (res[2].status !== 200) {
          this.$message.error('请求出错');
          this.tableLoading = false;

          return;
        }

        this.taskTableData = [...res[0].jobs];
        this.count = res[0].count;
        this.logMixins = [];
        this.indexId = null;

        this.tableLoading = false;
      });
    },

    afterCloseClear() {
      this.$confirm(this.$t('workRecord.confirmToClose'), this.$t('workRecord.note'), {
        confirmButtonText: this.$t('commonVariables.complete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
      }).then(() => {
        // 关闭清空
        this.drawerForm = { page: 1, pageSize: 20, nameCont: '', dateGte: '', dateLte: '' };
        this.taskTableData = [];
        this.logMixins = [];
        this.indexId = null;
        this.$emit('update:drawerVisible', false);
      });
    },
    inputTip(idx) {
      return this.indexId === idx;
    },
    resizeObserverSize() {
      this.$nextTick(() => {
        const headerHeight = this.$refs.drawerHeader?.offsetHeight ?? 0;
        const topBoxHeight = this.$refs.formContainer?.offsetHeight ?? 0;

        this.otherDomHeight = headerHeight + topBoxHeight + 66;
        this.tableHeight = window.innerHeight - this.otherDomHeight;
      });
    },
    clickThisRow(row, idx) {
      this.currentRow = row;
      this.indexId = idx;
      this.inputShow = true;
    },

    recordSave() {
      workRecordApi.submitTask({ logs: this.logMixins }).then(res => {
        if (res[2].status !== 200) {
          this.$message.error('请求失败');
          return;
        }
        this.$message.success(this.$t('workRecord.modifySuccess'));
        this.drawerForm.nameCont = '';
        this.taskTableData = [];
        this.logMixins = [];
        this.indexId = null;
        this.searchData();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
#taskFormDrawer {
  ::v-deep .el-form-item__label {
    font-weight: normal;
    color: #808694;
  }
  .btn {
    height: 32px;
    padding: 0 20px;
  }
  .drawerHeader {
    display: flex;
    font-size: 16px;
    height: 56px;
    font-weight: 500;
    color: #17233d;
    padding: 0 32px;
    align-items: center;
    margin: 0;
    .title {
      font-weight: 700;
      font-family: MicrosoftYahei, Helvetica, Arial, sans-serif;
    }
    .box {
      display: inline-block;
      position: relative;
      // min-width: 92px;
      min-height: 24px;
      margin-left: 8px;

      // background: #f5f7fa;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2d8cf0;
      .circle {
        position: absolute;
        top: calc(50% - 3px);
        left: 12px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: #2d8cf0;
      }
      .currentRord {
        color: #515a6e;
        display: inline-block;
        margin: 0;
        padding-left: 24px;
        padding-right: 12px;
        line-height: 24px;
      }
    }
    .header-btn-group {
      margin-left: auto;
    }
  }
  .app-box {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 2px;
      p {
        font-size: 16px;
        font-weight: 600;
        color: #19233b;
      }
      .header-btns {
        display: flex;
        align-items: center;
        height: 36px;
        font-size: 14px;
        .closeDrawder {
          margin-left: 16px;
        }
      }
    }

    .form-box {
      width: 100%;
      display: flex;
      flex: auto;
      justify-content: flex-start;
      flex-wrap: wrap;
      font-size: 14px;
      background-color: #fff;
      border-radius: 2px;
      font-size: 16px;
      font-weight: 500;
      padding: 20px 24px;
      padding-left: 32px;
      padding-bottom: 0;
      color: #17233d;
      border-top: 1px solid #e9e9e9;

      ::v-deep .el-form-item__content {
        line-height: 32px;
      }

      .des-oneRow {
        width: 100%;
        display: flex;
        flex: auto;
        justify-content: flex-start;
        align-items: center;
        // .projectName {
        //   margin-right: 20px;
        // }
      }

      // .des-secondRow {
      //   width: 100%;
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      //   margin-top: 16px;
      //   .projectWorkItem {
      //     margin-right: 20px;
      //   }
      // }

      .nameCont {
        width: 100%;
        padding: 16px 0;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        flex: auto;
        justify-content: flex-start;
        flex-wrap: nowrap;
      }

      // .el-form-item {
      .el-form-item {
        margin: 0;
        flex: auto;
        flex: 1;
        margin-right: 30px;
        // max-width: 220px;
        // min-width: 208px;
        padding: 10px 0;
        ::v-deep .el-form-item__label {
          color: #515a6e;
          font-weight: normal;
          font-size: 14px;
          height: 20px;
          padding: 0;
          line-height: 20px;
          margin-top: 0;
          margin-bottom: 7px;
        }
        .projInput {
          width: 280px !important;
        }

        @media only screen and (max-width: 1366px) {
          .projInput {
            width: 220px !important;
          }
        }
        ::v-deep .el-select {
          width: 100%;
        }
        ::v-deep .el-date-editor--daterange {
          width: 100%;
        }
      }

      // display: flex;
      // flex: auto;
      // flex-wrap: nowrap;
      // margin-bottom: 0;

      // margin: 0;
      // flex: auto;
      // margin-right: 30px;
      // max-width: 220px;
      // min-width: 208px;
      // padding: 10px 0;
      // ::v-deep .el-form-item__label {
      //   color: #515a6e;
      //   font-weight: normal;
      //   font-size: 14px;
      //   // height: 20px;
      //   padding: 0;
      //   // line-height: 20px;
      //   margin-top: 0;
      //   margin-bottom: 7px;
      // }
      // .projInput {
      //   // width: 230px !important;
      // }

      // .el-date-picker {
      //   height: 32px;
      // }
      // .el-input {
      //   width: 224px;
      // }
      // }

      ::v-deep .el-input__inner {
        min-width: 207px;
        height: 32px;
      }

      .btnForm {
        width: 65px;
        margin-left: 10px;
      }
    }
    .bottom-box {
      background-color: #fff;
      padding: 0 24px;
      padding-left: 32px;
      border-radius: 2px;
      .editServiceTable {
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        ::-webkit-scrollbar {
          width: 5px;
          height: 10px;
          border-radius: 15px;
        }
      }
    }
  }
  .tableContainer {
    margin: 0 32px;
    ::v-deep.el-table {
      color: #515a6e;
      border-radius: 4px;
      border-color: #e8eaec;

      //表格缺省图
      .show_empty .el-table__empty-text {
        display: initial;
      }
      /*   .el-table__empty-block {
    margin: 8% auto;
    .el-table__empty-text {
      background-image: url('../../../assets/nodata.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 240px !important;
      height: 196px;
      position: relative;
      &::before {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        transform: translateX(20px);
        bottom: -60px;
        content: '暂时没有任何数据呢～';
      }
    }
  } */

      //table_header
      .el-table__header-wrapper {
        border-radius: 4px 4px 0px 0px;
        .el-table__header th.el-table__cell {
          background-color: #f8f8f8f9;
          color: #515a6e;
          font-weight: 600;
          font-size: 14px;
          padding: 6px 0;
          height: 60px;
          line-height: 60px;
          box-sizing: border-box;
          .cell {
            height: auto !important;
          }
        }
      }
      .header-text {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        border-radius: 4px;

        .currentDColor {
          color: rgba(45, 140, 240, 1);
        }

        .dayStyle {
          font-size: 12px;
          font-weight: normal;
        }
      }
      //table_body

      .el-table__body td.el-table__cell {
        // cursor: pointer;
        padding: 4px 0;
        height: 40px;
        .cell {
          width: 97% !important;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .scope-input {
          margin: 0 auto;
          max-width: 5vw;
          position: relative;

          .el-input__inner {
            width: 100%;
            color: #515a6e;
            border: none;
            text-align: center;
            height: 29px;
            line-height: 29px;
            background-color: #fff;
          }

          .popoverStyle {
            position: absolute;
            top: 22%;
            right: 0%;
            .bianjibeizhu {
              font-size: 1.25em !important;
            }
          }
        }
        .inputItem {
          cursor: pointer;
        }

        .inputShadow {
          margin: 0 auto;
          max-width: 5vw !important;
          height: 29px;
          line-height: 29px;
          text-align: center;
        }
        .inputShadow:hover {
          border: none;
          text-align: center;
          background-color: #fff;
        }
      }
    }
  }

  @include tableClass;
}
</style>
