<!--
 * @Description:
 * @Author: hgy
 * @Date: 2022-7-10
 * @LastEditors: hgy
 * @Reference:
-->
<template>
  <el-table
    :class="{ show_empty: showEmpty }"
    v-loading="tableLoading"
    :data="cloneTableData"
    border
    :max-height="tableHeight"
    highlight-current-row
    @row-click="rowClick"
    empty-text=" "
  >
    <el-table-column :align="'left'" :label="$t('workRecord.serviceItem')" min-width="160px">
      <template slot-scope="scope">
        <div style="display: flex; align-content: left">
          <span>{{ chooseTree === 'affairs' ? scope.row.task : scope.row.servingName }}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column :align="'left'" min-width="160px">
      <template #header>
        <span>{{ chooseTree === 'project' ? $t('workRecord.projectWorkItem') : $t('workRecord.serviceChildItem') }}</span>
      </template>
      <template slot-scope="scope">
        <div style="display: flex; align-content: left">
          <span @click="showTaskDialog(scope.row.canShowJob, scope.row)" :class="{ taskContent: scope.row.canShowJob }">{{
            chooseTree === 'affairs' ? scope.row.task : scope.row.projectWorkItem
          }}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      v-if="chooseTree === 'project'"
      prop="contractNo"
      :label="$t('workRecord.contractNo')"
      min-width="100px"
      :align="'left'"
      show-overflow-tooltip
    >
    </el-table-column>

    <el-table-column :align="'center'" v-for="(item, idx) in weeklySums" :key="item.simpDes" :min-width="$i18n.locale == 'en' ? '86px' : '76px'">
      <template #header>
        <div
          :tabindex="idx"
          :class="['header-text', { 'translate-text': $i18n.locale == 'en' }, headerCellHoliday(item), headerCellStyle(item)]"
          @mouseover="showHeaderTips(item, idx)"
          @mouseout="removeFocus()"
          @focus="switchTabTitle(item, weeklyDay[idx])"
          @blur="switchTabTitle()"
        >
          <div :class="['weeklyToolTip', headerCellStyle(item)]" :style="{ display: idx === hoverIndex ? 'block' : 'none' }">
            <span class="tipHeaderTitle">当日总工时</span>
            <span>{{ weeklyDuration[item.weekIndex] }}小时</span>
          </div>
          <span class="cirStyle"></span>
          <div :class="[{ currentDColor: yearlyDay[idx] === currentDayDate }, 'holidayColor']">
            <span> {{ yearlyDay[idx] === currentDayDate ? $t('workRecord.today') : $t('workRecord.' + item.fullDes) }}</span>
            <div class="dayStyle">{{ weeklyDay[idx] }}</div>
          </div>
        </div>
      </template>
      <template slot-scope="scope">
        <div :class="[writeClass(scope.$index, item.simpDes), 'inputItem']">
          <template v-if="!inputTip(scope.$index, scope.row, item.simpDes)">
            <el-popover
              v-if="scope.row[item.simpDes].remark"
              placement="top"
              width="240"
              trigger="hover"
              :content="scope.row[item.simpDes].remark"
              popper-class="poppers"
            >
              <span slot="reference" class="popperRemarks inputShadow" @click="clickThisRow(scope.$index, scope.row, idx)">{{
                scope.row[item.simpDes].duration
              }}</span>
            </el-popover>
            <div v-else class="popperRemarks inputShadow" @click="clickThisRow(scope.$index, scope.row, idx)">
              {{ scope.row[item.simpDes].duration }}
            </div>
          </template>
          <div v-if="inputShow && inputTip(scope.$index, scope.row, item.simpDes)" class="scope-input">
            <el-input @change="toRecordChanges(scope.$index, item.simpDes)" :disabled="projectDisabled" v-model="scope.row[item.simpDes].duration">
            </el-input>
            <el-popover placement="top-end" width="270" trigger="click" popper-class="poppers" @show="popShow" @hide="popClose">
              <p class="title">备注</p>
              <el-input
                @change="remarkRecordCharge(scope.$index, item.simpDes)"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="请输入内容"
                v-model="scope.row[item.simpDes].remark"
              >
              </el-input>
              <div slot="reference" class="popoverStyle">
                <GLIcon
                  icon="icon-bianjibeizhu"
                  iconClass="icon-bianjibeizhu"
                  v-show="scope.$index === focusId && !iconColorSwitch && !projectDisabled"
                  class="bianjibeizhu"
                ></GLIcon>
                <GLIcon
                  icon="icon-bianjibeizhu-jizhong"
                  iconClass="icon-bianjibeizhu-jizhong"
                  v-show="scope.$index === focusId && indexId === scope.row[item.simpDes].weekNumber && iconColorSwitch"
                  class="bianjibeizhu"
                ></GLIcon>
              </div>
            </el-popover>
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column :align="'center'" prop="weekSum" min-width="90px">
      <template #header>
        <span>{{ $t('workRecord.myWeekStatistics') }}</span>
      </template>
      <template slot-scope="scope">
        {{ getWeekSum(scope.row) }}
      </template>
    </el-table-column>

    <el-table-column :align="'center'" prop="contractSum" min-width="105px">
      <template #header>
        <span>{{ $t('workRecord.contractWeekStatistics') }}</span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { dayFormat } from '@/utils/time-tools';
export default {
  name: 'WorkRecordTable',
  components: {},
  props: {
    tableLoading: {
      type: Boolean,
      default: false,
    },
    projectDisabled: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    weeklyDuration: {
      type: Array,
      default: () => {
        return [];
      },
    },
    chooseTree: {
      type: String,
      default: '',
    },
    tableHeight: {
      type: Number,
      default: 0,
    },
    holidayWeek: {
      type: Array,
      default: () => {
        return [];
      },
    },
    yearlyDay: {
      type: Array,
      default: () => {
        return [];
      },
    },
    weeklyDay: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      currentDayDate: '',
      // 填补2021年12月29和30的disabled空缺参数
      fixlimitWeeklyDisabled: false,
      mouseKeep: false,
      hoverIndex: -1,
      weeklyHeader: [],
      indexId: undefined,
      currentRow: {},
      inputShow: false,
      iconColorSwitch: false,
      focusId: undefined,
      resizeFlag: null,
      cloneTableData: [],
      serviceId: '',
      taskId: '',
      weekText: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
      changeItems: [],
      showEmpty: false,
      rowId: undefined,
      focusRowId: undefined,
      weeklySums: [
        { fullDes: 'monday', simpDes: 'mon', weekIndex: 0 },
        { fullDes: 'tuesday', simpDes: 'tue', weekIndex: 1 },
        { fullDes: 'wednesday', simpDes: 'wed', weekIndex: 2 },
        { fullDes: 'thursday', simpDes: 'thu', weekIndex: 3 },
        { fullDes: 'friday', simpDes: 'fri', weekIndex: 4 },
        { fullDes: 'saturday', simpDes: 'sat', weekIndex: 5 },
        { fullDes: 'sunday', simpDes: 'sun', weekIndex: 6 },
      ],
    };
  },
  watch: {
    weeklyDay: {
      deep: true,
      immediate: false,
      handler() {
        this.currentDayDate = dayFormat(+new Date(), 'YYYY-MM-DD');
      },
    },
    tableData: {
      deep: true,
      immediate: false,
      handler(val) {
        this.cloneTableData = JSON.parse(JSON.stringify(val));
        !val.length ? (this.showEmpty = true) : (this.showEmpty = false);
        this.changeItems = [];
        // 表格数据变化时，初始化作为当前行+ICON并行显示的指标参数focusId
        this.focusId = undefined;
        this.serviceId = undefined;
        this.taskId = undefined;
      },
    },
  },
  methods: {
    showTaskDialog(canShow, row) {
      /*       if (type === 'sub' && !row.servicechildItem) {
        return;
      }
      if (type === 'parent' && row.servicechildItem) {
        return;
      } */
      if (!canShow) {
        return;
      }
      this.$emit('taskDrawerExpand', row);
    },
    removeFocus() {
      this.hoverIndex = -1;
      this.mouseKeep = false;
    },
    showHeaderTips(item, idx) {
      if (this.mouseKeep) {
        return;
      }
      this.mouseKeep = true;
      this.hoverIndex = idx;
    },
    headerCellHoliday(item) {
      if (!this.holidayWeek.length || !this.weeklyDay.length) {
        return;
      }

      const weeklyIdx = item.weekIndex;
      let wekData = this.holidayWeek[weeklyIdx];

      // 普通工作日适用通用规则
      if (wekData.type === 'holiday') {
        return 'holidayHeader';
      }
      if (wekData.isWorkday && wekData.type === 'weekend') {
        return 'makeUpWorkDay';
      }
    },
    switchTabTitle(item, dateVal) {
      if (!this.holidayWeek.length || !this.weeklyDay.length) {
        return;
      }

      if (item) {
        const weeklyIdx = item.weekIndex;
        let weeklyHours = this.weeklyDuration[weeklyIdx];
        this.$emit('switchTabHours', [weeklyHours], dateVal);
      } else {
        this.$emit('switchTabHours', []);
      }
    },
    /* 该api会在每次调用的时候挨次对表头元素进行遍历，故可以利用这个特性和调休日数组以及工时周数组进行同步比对，return对应的样式 */
    headerCellStyle(item) {
      if (!this.holidayWeek.length || !this.weeklyDay.length) {
        return;
      }
      const weeklyIdx = item.weekIndex;
      let wekData = this.holidayWeek[weeklyIdx];
      // 普通工作日适用通用规则
      if (wekData.isWorkday && wekData.type !== 'weekend' && wekData.type !== 'holiday') {
        return this.hoursComputed(this.weeklyDuration[weeklyIdx], 1);
      }
      if (wekData.type === 'weekend' || wekData.type === 'holiday') {
        return this.hoursComputed(this.weeklyDuration[weeklyIdx], 0);
      }
    },
    /*     headerCellClass(item) {

    }, */
    // 针对工作日的样式
    hoursComputed(vals, filterBool) {
      if (filterBool && vals >= 0 && vals < 8) {
        return 'unquilfiedHours';
      } else if (!filterBool && vals > 0 && vals < 8) {
        return 'unquilfiedHours';
      } else if (!filterBool && vals <= 0) {
        return 'hiddenHours';
      }
      if (vals === 8) {
        return 'quilfiedHours';
      }
      if (vals > 8) {
        return 'overHours';
      }
    },
    inputTip(idx, row, des) {
      return idx === this.focusId && this.indexId === row[des].weekNumber;
    },
    popShow() {
      this.iconColorSwitch = true;
    },
    popClose() {
      this.iconColorSwitch = false;
    },
    //周工时总和
    getWeekSum(row) {
      let sum = 0;
      for (let i of this.weekText) {
        sum += Number(row[i].duration);
      }
      return sum;
    },

    /* 变更逻辑设计:失去焦点后判断是否是新值 是就记录并整理被改变的那个单元格关联的数据 用以提交 */
    toRecordChanges(idx, key) {
      this.weekText.includes(key) && this.checkNum(idx, key);
      if (this.cloneTableData[idx][key].duration !== this.tableData[idx][key].duration) {
        this.setChangeItems(idx, key);
      }

      this.$emit('changeObjsData', this.changeItems);
    },
    remarkRecordCharge(idx, key) {
      if (this.cloneTableData[idx][key].remark !== this.tableData[idx][key].remark) {
        this.setChangeItems(idx, key);
      }
      this.$emit('changeObjsData', this.changeItems);
    },
    // 备注和时长的变更后触发
    setChangeItems(idx, key) {
      this.changeItems.forEach((item, index) => {
        let workLogId = item.workLogId;
        let recordIndex = index;
        let weekIndex = item.weekNumber;
        // 注意，此处是为了反复填写同一项时的去重操作，以最后的改动为最新版本
        if (this.cloneTableData[idx][key].id === workLogId && this.cloneTableData[idx][key].weekNumber === weekIndex && idx === item.rowIdx) {
          this.changeItems.splice(recordIndex, 1);
        }
      });
      const submitLog = {
        weekId: this.cloneTableData[idx].weekId, //周id
        remark: this.cloneTableData[idx][key].remark, //信息备注
        weekNumber: this.cloneTableData[idx][key].weekNumber, //1-6指代周一到周六，7指代周日
        duration: this.cloneTableData[idx][key].duration, //工作时长
        workLogId: this.cloneTableData[idx][key].id, //工作记录id
      };
      if (this.chooseTree === 'project') {
        this.changeItems.push({
          ...submitLog,
          serviceId: this.cloneTableData[idx].serviceId, //任务id
          taskId: this.cloneTableData[idx].taskId, //任务id
          rowIdx: idx,
          weekKey: key,
        });
      } else {
        this.changeItems.push({
          ...submitLog,
          taskId: this.cloneTableData[idx].arrairTaskId, //任务id
          rowIdx: idx,
          weekKey: key,
        });
      }
    },
    //检查输入的值
    checkNum(idx, key) {
      const thisVal = this.cloneTableData[idx][key].duration;
      if (thisVal == '') return;

      const regNum = /^[0-9]+([.][5]{1})?$/;
      if (regNum.test(thisVal)) {
        this.cloneTableData[idx][key].duration = Number(thisVal);
      } else {
        this.cloneTableData[idx][key].duration = 0;
        this.$message.error('数值不合规，工时精度为半小时，例：0.5，1，1.5，8');
      }

      /*       if (thisVal < 0 || thisVal > 168) {
        this.cloneTableData[idx][key].duration = 0;
      } */
    },
    //记录点击了哪一行
    rowClick(row) {
      // rowClick在这里的作用是动态地根据当前点击行的状态做icon展示，
      // 通过点击不同行，然后检测当前行的唯一性参数是否等于当前行的唯一性参数来识别是否属于同一行,
      // 若不属于，则释放focusId，令切换前的单元行失焦
      if (this.chooseTree === 'project') {
        this.serviceId = row.serviceId;
        if (this.focusRowId !== row.serviceId) {
          this.focusId = undefined;
        }
      } else {
        this.taskId = row.arrairTaskId;
        if (this.focusRowId !== row.arrairTaskId) {
          this.focusId = undefined;
        }
      }
    },
    clickThisRow(id, row, idx) {
      this.iconColorSwitch = false;
      this.currentRow = row;
      this.indexId = ++idx;
      // fucusRowId是数据本身自带的当前行数据的属性id
      // focusId则是table组件返回的自带属性id，从上到下从0开始计数
      if (this.chooseTree === 'project') {
        this.focusRowId = row.serviceId;
      } else {
        this.focusRowId = row.arrairTaskId;
      }
      this.focusId = id;
      this.inputShow = true;
    },
    writeClass(idx, key) {
      const regNum = /^[0-9]+([.][5]{1})?$/;
      const thisVal = this.cloneTableData[idx][key].duration;

      return {
        isNewVal: this.cloneTableData && this.cloneTableData[idx][key].remark,
        isErr: thisVal !== '' && !regNum.test(thisVal),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
::v-deep .el-popover--plain {
  padding: 18px 20px;
}
.poppers {
  padding-top: 0;
  .title {
    margin: 4px 0;
    margin-top: 0;
    font-size: 14px;
    margin-left: 2px;
    font-weight: bold;
  }
  .el-textarea {
    font-family: MicrosoftYahei, Helvetica, Arial, sans-serif !important;
    font-size: 12px;
    width: 245px;
  }
}

::v-deep.el-table {
  color: #515a6e;
  border-radius: 4px;
  border-color: #e8eaec;

  //表格缺省图
  .show_empty .el-table__empty-text {
    display: initial;
  }
  /*   .el-table__empty-block {
    margin: 8% auto;
    .el-table__empty-text {
      background-image: url('../../../assets/nodata.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 240px !important;
      height: 196px;
      position: relative;
      &::before {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        transform: translateX(20px);
        bottom: -60px;
        content: '暂时没有任何数据呢～';
      }
    }
  } */

  //table_header
  .el-table__header-wrapper {
    border-radius: 4px 4px 0px 0px;
    .el-table__header th.el-table__cell {
      background-color: #f8f8f8f9;
      color: #515a6e;
      font-weight: 600;
      font-size: 14px;
      padding: 6px 0;
      height: 60px;
      line-height: 60px;
      box-sizing: border-box;
    }
  }

  //table_body
  .el-table__body td.el-table__cell {
    // cursor: pointer;
    padding: 4px 0;
    height: 40px;
    .cell {
      min-width: 3vw;
      max-width: 10vw;
    }
    .scope-input {
      margin: 0 auto;
      max-width: 5vw;
      position: relative;

      .el-input__inner {
        width: 100%;
        color: #515a6e;
        border: none;
        text-align: center;
        height: 29px;
        line-height: 29px;
        background-color: #fff;
      }

      .popoverStyle {
        position: absolute;
        top: 22%;
        right: 0%;
        .bianjibeizhu {
          font-size: 1.25em !important;
        }
      }
    }
    .inputItem {
      cursor: pointer;
    }
    .isNewVal {
      width: 52px;
      margin: 0 auto;
      .popperRemarks {
        display: inline-block;
        width: 100%;
        height: 29px;
        background: rgba(24, 144, 255, 0.05);
        border-radius: 2px;
        font-size: 14px;
        text-align: center;
        color: #2d8cf0;
        line-height: 29px;
      }
    }

    .isErr {
      .el-input__inner {
        color: #515a6e;
        color: red;
      }
    }
    .inputShadow {
      margin: 0 auto;
      max-width: 5vw !important;
      height: 29px;
      line-height: 29px;
      text-align: center;
    }
    .inputShadow:hover {
      border: none;
      text-align: center;
      background-color: #fff;
    }
  }
}
::v-deep .el-input__inner {
  color: #515a6e;
  height: 28px;
  line-height: 28px;
}
@include tableClass;
::v-deep.show_empty .el-table__empty-text {
  display: initial;
}
.show_empty {
  height: 710px !important;
}
// 自定义表头样式
.taskContent {
  cursor: pointer;
  color: #2d8cf0;
}

.header-text {
  margin: 0 auto;
  position: relative;
  padding-top: 3px;
  // margin: 0 auto;
  // margin-right: 0px;
  // text-align: center;
  width: 100% !important;
  height: 48px;
  border-radius: 4px;

  .currentDColor {
    color: rgba(45, 140, 240, 1);
  }
  .cirStyle {
    position: absolute;
    top: 3px;
    right: 0;
    width: 6px;
    height: 6px;
    background: #f8f8f9;
    border-radius: 50%;
  }
  .dayStyle {
    font-size: 12px;
    font-weight: normal;
  }
  .weeklyToolTip {
    display: none;
    text-align: center;
    z-index: 999999;
    position: absolute;
    left: 39px;
    top: 20px;
    min-width: 4vw;
    min-height: 38px;
    line-height: 18px;
    border-radius: 4px;
    span {
      display: block;
      margin: 0;
      font-weight: normal;
      font-size: 12px;
      color: #fff;
    }
    .tipHeaderTitle {
      margin-top: 1px;
    }
  }
}
.translate-text {
  width: 100% !important;
}
.header-text:hover {
  cursor: pointer;
  background: rgba(232, 233, 236, 0.5);
  /*   .cirStyle {
    background: rgba(232, 233, 236, 0.3);
  } */
}
// 假期
.holidayHeader {
  width: 100% !important;
  line-height: 22px;
  padding-top: 3px;
  padding-left: 5px;
  background-color: rgba(255, 236, 234, 1);
  color: rgba(219, 79, 42, 1) !important;
  .cirStyle {
    background: rgba(255, 236, 234, 1);
  }
  .holidayColor {
    color: rgba(219, 79, 42, 1) !important;
  }
}
.holidayHeader:hover {
  background-color: rgba(255, 236, 234, 0.6) !important;
}
.holidayHeader::after {
  font-weight: normal;
  content: '休';
  font-size: 10px;
  position: absolute;
  top: 1px;
  left: 2px;
}
.makeUpWorkDay {
  line-height: 22px;
  padding-top: 3px;
  padding-left: 5px;
  background-color: rgba(119, 185, 255, 0.2);
  color: #2d8cf0 !important;
  .cirStyle {
    background: rgba(119, 185, 255, 0.2);
  }
  .holidayColor {
    color: #2d8cf0 !important;
  }
}
.makeUpWorkDay:hover {
  background-color: rgba(119, 185, 255, 0.12);
}
.makeUpWorkDay::after {
  font-weight: normal;
  content: '班';
  font-size: 8px;
  position: absolute;
  top: 0;
  left: 0;
}
.unquilfiedHours {
  .weeklyToolTip {
    background: rgba(241, 157, 55, 0.7);
  }
  .cirStyle {
    background: rgba(241, 157, 55, 1);
  }
}
.quilfiedHours {
  .weeklyToolTip {
    background: rgba(89, 187, 115, 0.7);
  }

  .cirStyle {
    background: rgba(88, 187, 114, 1);
  }
}
.hiddenHours {
  .weeklyToolTip {
    display: none !important;
  }

  .cirStyle {
    display: none !important;
  }
}
.overHours {
  .weeklyToolTip {
    background: rgba(219, 79, 42, 0.7);
  }
  .cirStyle {
    background: rgba(219, 79, 42, 1);
  }
}
::v-deep .el-table__header-wrapper {
  .el-table__header {
    .el-table__cell {
      overflow: visible;
      padding-bottom: 0;
      .cell {
        overflow: visible;
        background: #f8f8f9;
        color: #525a6b;
        box-sizing: border-box;
      }
    }
  }
}
@media screen and (min-width: 350px) and (max-width: 900px) {
  ::v-deep .el-table__body td.el-table__cell {
    cursor: pointer;
    padding: 4px 0;
    height: 40px;
    .cell {
      min-width: 100% !important;
      max-width: 100% !important;
      padding: 0 5px !important;
    }
  }
  .header-text {
    width: 16px;
  }
  .holidayHeader {
    width: 100% !important;
  }
  .scope-input {
    margin: 0 auto;
    max-width: 100% !important;
    position: relative;
  }
}
@media screen and (min-width: 900px) and (max-width: 1550px) {
  .header-text {
    .weeklyToolTip {
      min-width: 6vw;
    }
  }
}
</style>
